import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import type { SelectorOption } from '../common';

const MODEL_OPTIONS: SelectorOption[] = [
    { label: 'Basic', value: 'basic' },
    { label: 'Standard', value: 'standard' },
    { label: 'Premium', value: 'premium' },
];

const ASPECT_RATIO_OPTIONS: SelectorOption[] = [
    { label: 'Square', value: 'square' },
    { label: 'Vertical', value: 'vertical' },
    { label: 'Horizontal', value: 'horizontal' },
];
const MEDIA_TYPE_OPTIONS: SelectorOption[] = [
    { label: 'Raster', value: 'raster' },
    { label: 'Vector', value: 'vector' },
];
interface UseGenerateImageFromTextParams {
    prompt: string | null;
    modelSelectedOption: SelectorOption;
    mediaTypeSelectedOption: SelectorOption;
    aspectRatioSelectedOption: SelectorOption;
    setPrompt: (prompt: string) => void;
    setModelSelectedOption: (modelOption: SelectorOption) => void;
    setMediaTypeSelectedOption: (mediaTypeSelectedOption: SelectorOption) => void;
    setAspectRatioSelectedOption: (aspectRatioSelectedOption: SelectorOption) => void;
}

export const useGenerateImageFromTextParams = (): UseGenerateImageFromTextParams => {
    const [searchParams, setSearchParams] = useSearchParams();

    const promptParam = searchParams.get('prompt');
    const modelParam = searchParams.get('modelSelectedOption');
    const mediaTypeParam = searchParams.get('mediaTypeSelectedOption');
    const aspectRatioParam = searchParams.get('aspectRatioSelectedOption');

    const [prompt, setPrompt] = useState(promptParam ?? '');
    const [modelSelectedOption, setModelSelectedOption] = useState<SelectorOption>(
        modelParam ? MODEL_OPTIONS.find((option) => option.value === modelParam) || MODEL_OPTIONS[0] : MODEL_OPTIONS[0],
    );
    const [aspectRatioSelectedOption, setAspectRatioSelectedOption] = useState<SelectorOption>(
        aspectRatioParam
            ? ASPECT_RATIO_OPTIONS.find((option) => option.value === aspectRatioParam) || ASPECT_RATIO_OPTIONS[0]
            : ASPECT_RATIO_OPTIONS[0],
    );

    const [mediaTypeSelectedOption, setMediaTypeSelectedOption] = useState<SelectorOption>(
        mediaTypeParam
            ? MEDIA_TYPE_OPTIONS.find((option) => option.value === mediaTypeParam) || MEDIA_TYPE_OPTIONS[0]
            : MEDIA_TYPE_OPTIONS[0],
    );

    useEffect(() => {
        const searchParamsObj: Record<string, string | string[]> = {};

        if (prompt) {
            searchParamsObj['prompt'] = prompt;
        }

        if (modelSelectedOption.value) {
            searchParamsObj['modelSelectedOption'] = modelSelectedOption.value;
        }

        if (mediaTypeSelectedOption.value) {
            searchParamsObj['mediaTypeSelectedOption'] = mediaTypeSelectedOption.value;
        }

        if (aspectRatioSelectedOption.value) {
            searchParamsObj['aspectRatioSelectedOption'] = aspectRatioSelectedOption.value;
        }

        setSearchParams(searchParamsObj);
    }, [prompt, modelSelectedOption, aspectRatioSelectedOption, mediaTypeSelectedOption, setSearchParams]);

    return {
        prompt,
        modelSelectedOption,
        aspectRatioSelectedOption,
        mediaTypeSelectedOption,
        setPrompt,
        setModelSelectedOption,
        setAspectRatioSelectedOption,
        setMediaTypeSelectedOption,
    };
};
