import { FourOhFour } from '@cimpress/react-components';
import { type JSX, Suspense } from 'react';
import { AdjustForDecorationTechnologyPage } from '../Pages/AdjustForDecorationTechnologyPage';
import { AdjustForSubstratePage } from '../Pages/AdjustForSubstratePage';
import { AdjustForSurfacePage } from '../Pages/AdjustForSurfacePage';
import { CompositePage } from '../Pages/CompositePage';
import { FontSwapPage } from '../Pages/FontSwapPage';
import { GeneratePage } from '../Pages/GeneratePage';
import { ImproveArtworkContrastPage } from '../Pages/ImproveArtworkContrastPage';
import { InspirationGeneratePage } from '../Pages/InspirationGeneratePage';
import { ResizePage } from '../Pages/ResizePage';
import { ResizePage as ResizeV0Page } from '../Pages/Experiment/Resize';
import { TransferCustomization } from '../Pages/TransferCustomizationPage';
import { TransferStyle } from '../Pages/TransferStylePage';
import GenerateImageFromText from '../Pages/GenerateImageFromText';
import {
    BatchGenerateFromFullBleed,
    BatchSherbertVsBrandExtraction,
    BatchResizeByDocument,
    BatchResizeByProduct,
    BatchTransferCustomization,
    BatchTransferStyle,
    GenerateV3WithSherbertResultsPage,
    GenerateWithDynamicLayoutPage,
    HiddenPageIndexPage,
    KrReportAdjustForDecorationTechnologyPage,
    KrReportAdjustForSubstratePage,
    KrReportAdjustForSurfacePage,
    KrReportImproveContrastPage,
    KrReportMainOptionPage,
    KrReportResizePage,
    KrReportTransferCustomizationPage,
    KrReportTransferStylePage,
    LayoutComposerVsGenDesignResults,
    PersonalizedGallery,
    PreviewCardVisualizationsPage,
    PurposelessTransferCustomizations,
    QaPage,
    ResizeQaPage,
    SettingsPage,
    ComposeQa,
} from './lazyLoaders';
import { PageLoadFallback } from './PageLoadFallback';
export interface Route {
    href: string;
    title: string;
    element: JSX.Element;
}

export interface RouteWithProblemSpace extends Route {
    problemSpace: string;
}

export interface ProblemSpaceRoutes {
    name: string;
    routes: Route[];
}

const artworkAdaptationRoutes: Route[] = [
    {
        href: 'resize',
        title: 'Resize',
        element: <ResizePage />,
    },
    {
        href: 'adjustforsubstrate',
        title: 'Adapt to Substrate Color',
        element: <AdjustForSubstratePage />,
    },
    {
        href: 'adjustfordecotech',
        title: 'Adapt to Decoration Technology',
        element: <AdjustForDecorationTechnologyPage />,
    },
    {
        href: 'adjustforsurface',
        title: 'Adapt to Product Surface',
        element: <AdjustForSurfacePage />,
    },
];

const artworkCompositionRoutes: Route[] = [
    {
        href: 'generate',
        title: 'Generate',
        element: <GeneratePage />,
    },
    {
        href: 'composite',
        title: 'Compose',
        element: <CompositePage />,
    },
    {
        href: 'fontswap',
        title: 'Font Swap',
        element: <FontSwapPage />,
    },
];

const artworkPreservationRoutes: Route[] = [
    {
        href: 'transfer',
        title: 'Transfer Customizations',
        element: <TransferCustomization />,
    },
    {
        href: 'transferstyle',
        title: 'Transfer Style',
        element: <TransferStyle />,
    },
];

const artworkQualityRoutes: Route[] = [
    {
        href: 'improveartworkcontrast',
        title: 'Improve Artwork Contrast',
        element: <ImproveArtworkContrastPage />,
    },
];

const artworkInspirationRoutes: Route[] = [
    {
        href: 'inspirationgenerate',
        title: 'Generate',
        element: <InspirationGeneratePage />,
    },
    {
        href: 'generate-image-from-text',
        title: 'Generate Image From Text',
        element: <GenerateImageFromText />,
    },
];

export const krReportSubroutes: Route[] = [
    {
        href: 'krreport/resize',
        title: 'Resize',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <KrReportResizePage />
            </Suspense>
        ),
    },
    {
        href: 'krreport/adjustforsubstrate',
        title: 'Adapt to Substrate Color',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <KrReportAdjustForSubstratePage />
            </Suspense>
        ),
    },
    {
        href: 'krreport/adjustfordecotech',
        title: 'Adapt to Decoration Technology',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <KrReportAdjustForDecorationTechnologyPage />
            </Suspense>
        ),
    },
    {
        href: 'krreport/adjustforsurface',
        title: 'Adapt to Product Surface',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <KrReportAdjustForSurfacePage />
            </Suspense>
        ),
    },
    {
        href: 'krreport/transferstyle',
        title: 'Transfer Style',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <KrReportTransferStylePage />
            </Suspense>
        ),
    },
    {
        href: 'krreport/transfercustomizations',
        title: 'Transfer Customizations',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <KrReportTransferCustomizationPage />
            </Suspense>
        ),
    },
    {
        href: 'krreport/improveartworkcontrast',
        title: 'Improve Artwork Contrast',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <KrReportImproveContrastPage />
            </Suspense>
        ),
    },
];

export const hiddenRoutes: Route[] = [
    {
        href: 'hiddenpages',
        title: 'Hidden pages',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <HiddenPageIndexPage />
            </Suspense>
        ),
    },
    {
        href: 'BatchTransfer',
        title: 'Batch transfer customizations',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <BatchTransferCustomization />
            </Suspense>
        ),
    },
    {
        href: 'BatchTransferStyle',
        title: 'Batch transfer style',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <BatchTransferStyle />
            </Suspense>
        ),
    },
    {
        href: 'ByProduct',
        title: 'Batch resize by product',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <BatchResizeByProduct />
            </Suspense>
        ),
    },
    {
        href: 'ByDocument',
        title: 'Batch resize by document',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <BatchResizeByDocument />
            </Suspense>
        ),
    },
    {
        href: 'krreport',
        title: 'KR Report home page',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <KrReportMainOptionPage />
            </Suspense>
        ),
    },
    ...krReportSubroutes.map((route) => ({ ...route, title: `KR Report for ${route.title}` })),
    {
        href: 'settings',
        title: 'Settings',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <SettingsPage />
            </Suspense>
        ),
    },
    {
        href: 'generate-v3-with-sherbert',
        title: 'Generate V3 with sherbert result',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <GenerateV3WithSherbertResultsPage />
            </Suspense>
        ),
    },
    {
        href: 'generate-v0-dynamic-layout',
        title: 'Generate with Dynamic Layout V0',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <GenerateWithDynamicLayoutPage />
            </Suspense>
        ),
    },
    {
        href: 'batch-fullbleed-generate',
        title: 'Batch Fullbleed Generate',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <BatchGenerateFromFullBleed />
            </Suspense>
        ),
    },
    {
        href: 'batch-sherbert-vs-brand-extraction',
        title: 'Batch sherbertExtraction vs brandExtraction',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <BatchSherbertVsBrandExtraction />
            </Suspense>
        ),
    },
    {
        href: 'qa',
        title: 'Quality Assurance',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <QaPage />
            </Suspense>
        ),
    },
    {
        href: 'resize-qa',
        title: 'Resize Quality Assurance',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <ResizeQaPage />
            </Suspense>
        ),
    },
    {
        href: 'compose-qa',
        title: 'Compose Quality Assurance',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <ComposeQa />
            </Suspense>
        ),
    },

    {
        href: 'personalized-gallery',
        title: 'Personalized Gallery',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <PersonalizedGallery />
            </Suspense>
        ),
    },
    {
        href: 'purposeless-transfer-customizations',
        title: 'Purposeless Transfer Customizations',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <PurposelessTransferCustomizations />
            </Suspense>
        ),
    },
    {
        href: 'previewCards',
        title: 'PreviewCard Visualizations',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <PreviewCardVisualizationsPage />
            </Suspense>
        ),
    },
    {
        href: 'layout-composer-results',
        title: 'Layout Composer Vs Gendesign Results.',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <LayoutComposerVsGenDesignResults />
            </Suspense>
        ),
    },
    {
        href: 'resize-v0',
        title: 'Experimantal Resize',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <ResizeV0Page />
            </Suspense>
        ),
    },
    {
        href: '*',
        title: '404 - Not Found',
        element: (
            <FourOhFour message="Oops! The page you are looking for doesn't exist. Please check the URL or go back to the homepage." />
        ),
    },
];

export const routesByProblemSpace: ProblemSpaceRoutes[] = [
    {
        name: 'Artwork Adaptation',
        routes: artworkAdaptationRoutes,
    },
    {
        name: 'Artwork Composition',
        routes: artworkCompositionRoutes,
    },
    {
        name: 'Artwork Preservation',
        routes: artworkPreservationRoutes,
    },
    {
        name: 'Artwork Quality',
        routes: artworkQualityRoutes,
    },
    {
        name: 'Artwork Inspiration',
        routes: artworkInspirationRoutes,
    },
];

export const routes: RouteWithProblemSpace[] = routesByProblemSpace.flatMap(({ name, routes }) =>
    routes.map((route) => ({ ...route, problemSpace: name })),
);
