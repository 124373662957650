import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import type { Physicality } from './types';

interface UseResizeParams {
    document: string;
    physicalities: Physicality[];
    surfaceSpecificationUrl: string;
    surfaceSpecification: any;
    sourceSurfaceSpecificationUrl: string;
    sourceSurfaceSpecification: any;
    customPhysicality?: Physicality;
    debug: boolean;
    minimumFontSize: string;
    isBetaEndpoint: boolean;
    priority: string;
    setDocument: (document: string) => void;
    setPhysicalities: (physicalities: Physicality[]) => void;
    setSurfaceSpecificationUrl: (url: string) => void;
    setSurfaceSpecification: (surfaceSpec: any) => void;
    setSourceSurfaceSpecificationUrl: (url: string) => void;
    setSourceSurfaceSpecification: (surfaceSpec: any) => void;
    setCustomPhysicality: (physicality: Physicality | undefined) => void;
    setDebug: (debug: boolean) => void;
    setMinimumFontSize: (minimumFontSize: string) => void;
    setIsBetaEndpoint: (isBetaEndpoint: boolean) => void;
    setPriority: (priority: string) => void;
}

export const useResizeParams = (): UseResizeParams => {
    const [searchParams, setSearchParams] = useSearchParams();
    const physicalitiesParam = searchParams.get('physicalities');
    const customPhysicalitiesParam = searchParams.get('customphysicalities');

    const documentUrl = searchParams.get('documentUrl');
    const physicalitiesJson = physicalitiesParam ? JSON.parse(physicalitiesParam) : [];
    const customPhysicalitiesJson = customPhysicalitiesParam ? JSON.parse(customPhysicalitiesParam) : '';
    const surfaceSpecificationParam = searchParams.get('surfaceSpecification');
    const surfaceSpecificationUrlParam = searchParams.get('surfaceSpecificationUrl');
    const sourceSurfaceSpecificationParam = searchParams.get('sourceSurfaceSpecification');
    const sourceSurfaceSpecificationUrlParam = searchParams.get('sourceSurfaceSpecificationUrl');
    const debugMode = searchParams.get('debug');
    const minimumFontSizeParam = searchParams.get('minimumFontSize');
    const isBetaEndpointParam = searchParams.get('isBetaEndpoint');
    const prioritySearchParam = searchParams.get('priority');

    const [document, setDocument] = useState(documentUrl || '');
    const [physicalities, setPhysicalities] = useState<Physicality[]>(physicalitiesJson || []);
    const [customPhysicality, setCustomPhysicality] = useState<Physicality | undefined>(customPhysicalitiesJson);
    const [surfaceSpecificationUrl, setSurfaceSpecificationUrl] = useState(surfaceSpecificationUrlParam || '');
    const [surfaceSpecification, setSurfaceSpecification] = useState(
        surfaceSpecificationParam ? JSON.parse(surfaceSpecificationParam) : undefined,
    );
    const [sourceSurfaceSpecificationUrl, setSourceSurfaceSpecificationUrl] = useState(
        sourceSurfaceSpecificationUrlParam || '',
    );
    const [sourceSurfaceSpecification, setSourceSurfaceSpecification] = useState(
        sourceSurfaceSpecificationParam ? JSON.parse(sourceSurfaceSpecificationParam) : undefined,
    );
    const [debug, setDebug] = useState(debugMode === 'true');
    const [minimumFontSize, setMinimumFontSize] = useState(minimumFontSizeParam || '');
    const [isBetaEndpoint, setIsBetaEndpoint] = useState(!!isBetaEndpointParam || false);
    const [priority, setPriority] = useState(prioritySearchParam || 'speed');

    useEffect(() => {
        const searchParamsObj: Record<string, string> = {};

        if (document) {
            searchParamsObj['documentUrl'] = document;
        }

        if (physicalities.length > 0) {
            searchParamsObj['physicalities'] = JSON.stringify(physicalities);
        }

        if (surfaceSpecificationUrl) {
            searchParamsObj['surfaceSpecificationUrl'] = surfaceSpecificationUrl;
        }

        if (surfaceSpecification) {
            searchParamsObj['surfaceSpecification'] = JSON.stringify(surfaceSpecification);
        }

        if (customPhysicality) {
            searchParamsObj['customphysicalities'] = JSON.stringify(customPhysicality);
        }

        if (minimumFontSize) {
            searchParamsObj['minimumFontSize'] = minimumFontSize;
        }

        if (isBetaEndpoint) {
            searchParamsObj['isBetaEndpoint'] = isBetaEndpoint.toString();
        }

        if (priority) {
            searchParamsObj['priority'] = priority.toString();
        }

        if (surfaceSpecificationUrl) {
            searchParamsObj['sourceSurfaceSpecificationUrl'] = sourceSurfaceSpecificationUrl;
        }

        if (surfaceSpecification) {
            searchParamsObj['sourceSurfaceSpecification'] = JSON.stringify(sourceSurfaceSpecification);
        }

        setSearchParams(searchParamsObj);
    }, [
        document,
        physicalities,
        surfaceSpecificationUrl,
        surfaceSpecification,
        sourceSurfaceSpecificationUrl,
        sourceSurfaceSpecification,
        customPhysicality,
        minimumFontSize,
        isBetaEndpoint,
        priority,
        setSearchParams,
    ]);

    return {
        document,
        physicalities,
        surfaceSpecificationUrl,
        surfaceSpecification,
        sourceSurfaceSpecificationUrl,
        sourceSurfaceSpecification,
        customPhysicality,
        debug,
        minimumFontSize,
        isBetaEndpoint,
        priority,
        setDocument,
        setPhysicalities,
        setSurfaceSpecificationUrl,
        setSurfaceSpecification,
        setSourceSurfaceSpecificationUrl,
        setSourceSurfaceSpecification,
        setCustomPhysicality,
        setDebug,
        setMinimumFontSize,
        setIsBetaEndpoint,
        setPriority,
    };
};
