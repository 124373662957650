export function parseSize(size: string): number | undefined {
    const sizeRegex = /^[0-9.]+px$/;

    return sizeRegex.test(size) ? +size.substring(0, size.length - 2) : undefined;
}

interface ImageDimensions {
    width: number;
    height: number;
}

export async function getImageDimensions(imageUrl: string, signal: AbortSignal): Promise<ImageDimensions | undefined> {
    return new Promise<ImageDimensions | undefined>((resolve) => {
        const image = new Image();

        const abortRequest = () => {
            image.src = '';
            resolve(undefined);
        };

        if (signal.aborted) {
            abortRequest();
        }

        signal.addEventListener('abort', abortRequest);

        image.onload = () => {
            signal.removeEventListener('abort', abortRequest);
            resolve({ width: image.naturalWidth, height: image.naturalHeight });
        };

        image.onerror = () => {
            signal.removeEventListener('abort', abortRequest);
            resolve(undefined);
        };

        image.src = imageUrl;
    });
}
