import { Select, TabCard, TextField } from '@cimpress/react-components';
import { lazy, Suspense, useState } from 'react';
import presetSurfaceUrls from '../data/surfaceUrl.json';
import type { SelectorOption } from './common/types';
import { Control } from './layout';

const SurfaceFromProductSelector = lazy(() => import('./SurfaceFromProductSelector'));
const SurfaceJsonTab = lazy(() => import('./SurfaceJsonTab'));

interface SurfaceSelectorProps {
    surfaceSpecificationUrl: string;
    surfaceSpecification?: any;
    setSurfaceSpecificationUrl: (surfaceSpecificationUrl: string) => void;
    setSurfaceSpecification?: (surfaceSpecification: any | undefined) => void;
    // We are accepting different presetSurfaceUrls from Generate in node because we do not support embroidery and single color surfaces yet.
    // TODO: This can be removed once we start supporting all deco-techs
    presetSurfaceUrlsFromPage?: SelectorOption[];
    presetSurfaceJsonFromPage?: { label: string; value: any }[];
}

const CUSTOM_LABEL = 'Custom';

import classes from './SurfaceSelector.module.css';

export const SurfaceSelector = ({
    surfaceSpecificationUrl,
    surfaceSpecification,
    setSurfaceSpecificationUrl,
    setSurfaceSpecification,
    presetSurfaceUrlsFromPage,
    presetSurfaceJsonFromPage,
}: SurfaceSelectorProps) => {
    const [presetSurfaceUrl, setPresetSurfaceUrl] = useState<SelectorOption>({ label: CUSTOM_LABEL, value: '' });

    const onChangeSelect = (selected: SelectorOption | null | undefined) => {
        if (!selected) {
            return;
        }

        setPresetSurfaceUrl(selected);

        if (selected.label !== CUSTOM_LABEL) {
            setSurfaceSpecificationUrl(selected.value);
        }
    };

    const surfaceSpecUrlTabContent = (
        <Control>
            <Control.Title>Surface Specification URL</Control.Title>
            <Select
                onChange={onChangeSelect}
                value={presetSurfaceUrl}
                options={presetSurfaceUrlsFromPage ?? presetSurfaceUrls}
                label="Surface Specification URL"
            />
            <TextField
                label="Surface Specification URL"
                value={surfaceSpecificationUrl}
                onChange={(e) => setSurfaceSpecificationUrl(e.target.value)}
            />
        </Control>
    );

    const surfaceSpecFromProduct = (
        <Suspense fallback="Loading...">
            <SurfaceFromProductSelector onSelect={setSurfaceSpecificationUrl} />
        </Suspense>
    );

    const surfaceSpecJsonTabContent = setSurfaceSpecification && (
        <>
            <Suspense fallback="Loading...">
                <SurfaceJsonTab
                    presetSurfaceJsonFromPage={presetSurfaceJsonFromPage}
                    setSurfaceSpecification={setSurfaceSpecification}
                    surfaceSpecification={surfaceSpecification}
                />
            </Suspense>
        </>
    );

    const baseTabs = [
        {
            name: 'From URL',
            block: surfaceSpecUrlTabContent,
        },
        {
            name: 'From Product',
            block: surfaceSpecFromProduct,
        },
    ];

    // Conditionally add the surfaceSpecJsonTabContent tab. V2 does not accept surfaceSpecJson
    const tabs = setSurfaceSpecification
        ? [
              ...baseTabs,
              {
                  name: 'From JSON',
                  block: surfaceSpecJsonTabContent,
              },
          ]
        : baseTabs;

    const handleTabChanged = (_: any, selectedKey: number) => {
        if (selectedKey === 2) {
            setSurfaceSpecification && setSurfaceSpecification(surfaceSpecification);
            setSurfaceSpecificationUrl('');
        } else {
            setSurfaceSpecification && setSurfaceSpecification(undefined);
            setSurfaceSpecificationUrl(surfaceSpecificationUrl);
        }
    };

    return (
        <>
            <TabCard
                selectedIndex={surfaceSpecification ? 2 : 0}
                tabs={tabs}
                onSelect={handleTabChanged}
                className={classes.tabs}
            />
        </>
    );
};
