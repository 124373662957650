import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

interface UseCompositeParams {
    targetDocumentUrl: string;
    components: any;
    useSmartCompose: boolean;
    setTargetDocumentUrl: (url: string) => void;
    setComponents: (components: any) => void;
    setUseSmartCompose: (flag: boolean) => void;
}

export const useCompositeParams = (): UseCompositeParams => {
    const [searchParams, setSearchParams] = useSearchParams();

    const documentUrl = searchParams.get('targetDocumentUrl');
    const componentsParam = searchParams.get('components');
    const useSmartComposeParam = searchParams.has('useSmartCompose')
        ? searchParams.get('useSmartCompose') === 'true'
        : true;

    const [targetDocumentUrl, setTargetDocumentUrl] = useState(documentUrl || '');
    const [components, setComponents] = useState(componentsParam ? JSON.parse(componentsParam) : undefined);
    const [useSmartCompose, setUseSmartCompose] = useState(useSmartComposeParam);

    useEffect(() => {
        const searchParamsObj: Record<string, string> = {};

        if (targetDocumentUrl) {
            searchParamsObj['targetDocumentUrl'] = targetDocumentUrl;
        }

        if (components) {
            searchParamsObj['components'] = JSON.stringify(components);
        }

        if (useSmartCompose) {
            searchParamsObj['useSmartCompose'] = `${useSmartCompose}`;
        }

        setSearchParams(searchParamsObj);
    }, [targetDocumentUrl, components, useSmartCompose, setSearchParams]);

    return {
        targetDocumentUrl,
        components,
        useSmartCompose,
        setTargetDocumentUrl,
        setComponents,
        setUseSmartCompose,
    };
};
