import { useState } from 'react';
import sampleImages from '../../data/inspirationGenerate/sampleImageAssets.json';
import { useDebouncedEffect } from '../../tools';
import { ControlTitleToggle } from '../common';
import { Control } from '../layout';
import { type InspirationImageAsset, InspirationImageAssetEditor } from './images';

export interface ImageAssetControlProps {
    initialImageAsset: InspirationImageAsset | null;
    setImageAsset: (value: string) => void;
}

export const ImageAssetControl = ({ initialImageAsset, setImageAsset }: ImageAssetControlProps) => {
    const [customImage, setCustomImage] = useState<InspirationImageAsset | null>(initialImageAsset);
    const [isCustomImageRequired, setIsCustomImageRequired] = useState(!!initialImageAsset);

    useDebouncedEffect(
        () => {
            if (isCustomImageRequired && customImage) {
                setImageAsset(JSON.stringify(customImage));
            } else {
                setImageAsset('');
            }
        },
        [customImage, isCustomImageRequired, setImageAsset],
        500,
    );

    return (
        <Control>
            <ControlTitleToggle
                title="Image Asset"
                isOn={isCustomImageRequired}
                onToggle={(isOn) => {
                    if (isOn) {
                        setIsCustomImageRequired(true);
                    } else {
                        setIsCustomImageRequired(false);
                        setCustomImage(null);
                    }
                }}
            />

            {isCustomImageRequired && (
                <InspirationImageAssetEditor
                    initialValue={customImage}
                    onChange={setCustomImage}
                    sampleValues={sampleImages}
                />
            )}
        </Control>
    );
};
