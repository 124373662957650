import { useEffect, useState, type CSSProperties } from 'react';

export interface AnchorImageProps {
    documentUrl?: string;
    previewUrl: string;
    alt: string;
    useLoadingTransition?: boolean;
}
const ANCHOR_IMAGE_STYLE: CSSProperties = {
    maxWidth: '100%',
    minWidth: '10rem',
    transition: 'opacity 0.5s ease-in-out',
};
const LOADING_IMAGE_STYLE: CSSProperties = { ...ANCHOR_IMAGE_STYLE, opacity: 0.2 };

export const AnchorImage = ({ documentUrl = '', previewUrl, alt, useLoadingTransition = false }: AnchorImageProps) => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
    }, [previewUrl]);

    const handleLoad = () => setIsLoading(false);

    return (
        <a href={documentUrl} target="blank">
            <img
                src={previewUrl}
                alt={alt}
                style={isLoading && useLoadingTransition ? LOADING_IMAGE_STYLE : ANCHOR_IMAGE_STYLE}
                onLoad={handleLoad}
            />
        </a>
    );
};
