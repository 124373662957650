import { useContext, useEffect, useState } from 'react';
import { getTransferCustomizationUrl } from '../api';
import {
    CardBuilder,
    Page,
    PreviewRow,
    PreviewRows,
    PreviewSection,
    TransferCustomizationControls,
} from '../Components';
import { getUrl } from '../tools';
import { useTransferCustomizationParams } from '../Components/TransferCustomization/useTransferCustomizationParams';
import { ApiKeyContext } from '../App';

const intro = `This is the page to test our transfer endpoint.\nFill in the source and target to see the transfer result`;

export const TransferCustomization = () => {
    const transferCustomizationParams = useTransferCustomizationParams();

    const [originRow, setOriginRow] = useState(PreviewRow());
    const [resultRow, setResultRow] = useState(PreviewRow());
    const apiKey = useContext(ApiKeyContext);

    useEffect(() => {
        if (transferCustomizationParams.sourceUrl && transferCustomizationParams.targetUrl) {
            const resultCard = CardBuilder.ofType('documentUrl')
                .withTitle('Result Document')
                .withData({
                    documentUrl:
                        getTransferCustomizationUrl(
                            transferCustomizationParams.sourceUrl,
                            transferCustomizationParams.targetUrl,
                            transferCustomizationParams.transferCustomerAssets,
                            apiKey,
                            transferCustomizationParams.useSmartTextTransfer,
                        ) ?? '',
                    backgroundColor: 'FFFFFF',
                })
                .build();
            const row = PreviewRow(resultCard);
            setResultRow(row);
        } else {
            setResultRow(PreviewRow());
        }
    }, [
        transferCustomizationParams.sourceUrl,
        transferCustomizationParams.targetUrl,
        transferCustomizationParams.transferCustomerAssets,
        transferCustomizationParams.useSmartTextTransfer,
        apiKey,
    ]);

    useEffect(() => {
        const row = PreviewRow();

        if (transferCustomizationParams.sourceUrl) {
            if (!transferCustomizationParams.useJsonSource) {
                const sourceCard = CardBuilder.ofType('documentUrl')
                    .withTitle('Source Document')
                    .withData({ documentUrl: transferCustomizationParams.sourceUrl })
                    .build();
                row.push(sourceCard);
            }
        }

        if (transferCustomizationParams.targetUrl) {
            const targetCard = CardBuilder.ofType('documentUrl')
                .withTitle('Target Document')
                .withData({ documentUrl: getUrl(transferCustomizationParams.targetUrl) ?? '' })
                .build();
            row.push(targetCard);
        }

        setOriginRow(row);
    }, [
        transferCustomizationParams.sourceUrl,
        transferCustomizationParams.targetUrl,
        transferCustomizationParams.useJsonSource,
    ]);

    return (
        <Page>
            <Page.Title>Transfer Customization</Page.Title>

            <Page.Controls>
                <TransferCustomizationControls
                    sourceUrl={transferCustomizationParams.sourceUrl}
                    targetUrl={transferCustomizationParams.targetUrl}
                    transferCustomerAssets={transferCustomizationParams.transferCustomerAssets}
                    useSmartTextTransfer={transferCustomizationParams.useSmartTextTransfer}
                    setSourceUrl={transferCustomizationParams.setSourceUrl}
                    setTargetUrl={transferCustomizationParams.setTargetUrl}
                    setTransferCustomerAssets={transferCustomizationParams.setTransferCustomerAssets}
                    setUseSmartTextTransfer={transferCustomizationParams.setUseSmartTextTransfer}
                />
            </Page.Controls>

            <Page.Content>
                <PreviewSection
                    introduction={intro}
                    showIntro={!transferCustomizationParams.sourceUrl && !transferCustomizationParams.targetUrl}
                    rows={PreviewRows(originRow, resultRow)}
                />
            </Page.Content>
        </Page>
    );
};
