import { Alert, Select } from '@cimpress/react-components';
import { useContext, useEffect, useState } from 'react';
import { ApiKeyContext } from '../App';
import { Control, Page } from '../Components';
import { options, useAdjustForDecoTechParams } from '../Components/AdjustForDecoTech/useAdjustForDecoTechParams';
import {
    CardBuilder,
    CustomSelector,
    PreviewRow,
    PreviewRows,
    PreviewSection,
    type SelectorOption,
} from '../Components/common';
import { useSampleDocuments } from '../Components/common/useSampleDocuments';
import { getAdjustForDecorationTechnologyUrl } from '../api';
import { getUrl, isUrl } from '../tools';

export const AdjustForDecorationTechnologyPage = () => {
    const documents = useSampleDocuments('adjustForDecotech_source_documents.json');

    const adjustForDecotechParams = useAdjustForDecoTechParams();

    const [sourceRow, setSourceRow] = useState(PreviewRow());
    const [resultRow, setResultRow] = useState(PreviewRow());
    const apiKey = useContext(ApiKeyContext);

    useEffect(() => {
        const sourceCard = CardBuilder.ofType('documentUrl')
            .withTitle('Source Artwork')
            .withData({ documentUrl: adjustForDecotechParams.document })
            .build();
        setSourceRow(PreviewRow(sourceCard));
    }, [adjustForDecotechParams.document]);

    useEffect(() => {
        const resultCard = CardBuilder.ofType('documentUrl')
            .withTitle(`Result for ${adjustForDecotechParams.targetDecorationTechnology.value}`)
            .withData({
                documentUrl:
                    getAdjustForDecorationTechnologyUrl(
                        adjustForDecotechParams.document,
                        adjustForDecotechParams.targetDecorationTechnology.value,
                        apiKey,
                    ) ?? '',
                backgroundColor: 'FFFFFF',
            })
            .build();
        setResultRow(PreviewRow(resultCard));
    }, [adjustForDecotechParams.document, adjustForDecotechParams.targetDecorationTechnology, apiKey]);

    const setDocumentUrl = (docUrlOrToken: string) => {
        adjustForDecotechParams.setDocument(getUrl(docUrlOrToken) ?? docUrlOrToken);
    };

    const onChangeSelected = (newSelected: SelectorOption | null | undefined) => {
        if (!newSelected) {
            return;
        }
        adjustForDecotechParams.setTargetDecorationTechnology(newSelected);
    };

    return (
        <Page>
            <Page.Title>Adapt to Decoration Technology</Page.Title>

            <Page.Controls>
                <Control>
                    <Control.Title>Source Document or Template</Control.Title>
                    <CustomSelector
                        selectorLabel="Document (or template)"
                        textfieldLabel="Document URL, Template URL or Template Token"
                        data={documents}
                        value={adjustForDecotechParams.document ?? ''}
                        setValue={setDocumentUrl}
                        validator={isUrl}
                    />
                </Control>

                <Control>
                    <Control.Title>Target Decoration Technology</Control.Title>

                    <Select
                        onChange={onChangeSelected}
                        value={adjustForDecotechParams.targetDecorationTechnology}
                        options={options}
                        label="Decoration technology"
                    />

                    <Alert
                        status="info"
                        dismissible={false}
                        message="Previewing the embroidery result may take some time."
                    />
                </Control>
            </Page.Controls>

            <Page.Content>
                <PreviewSection
                    introduction={
                        'This is a page to test the adjust for decoration technology endpoint. Fill in the source document and target decotech to see the result.'
                    }
                    showIntro={!adjustForDecotechParams.document}
                    rows={PreviewRows(sourceRow, resultRow)}
                />
            </Page.Content>
        </Page>
    );
};
