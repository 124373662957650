import { TextField } from '@cimpress/react-components';
import { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getImproveArtworkContrastUrl } from '../api';
import { ApiKeyContext } from '../App';
import { Control, Page } from '../Components';
import { CardBuilder, CustomSelector, PreviewRow, PreviewRows, PreviewSection } from '../Components/common';
import { useSampleDocuments } from '../Components/common/useSampleDocuments';
import { MINIMUM_CONTRAST_RATIO_PARAM, SOURCE_URL_PARAM } from '../constants';
import { getNumberParam, getUrl, isUrl } from '../tools';

export const ImproveArtworkContrastPage = () => {
    const predefinedDocuments = useSampleDocuments('improveArtworkContrast_source_documents.json');
    const [searchParams, setSearchParams] = useSearchParams();
    const sourceUrlParam = searchParams.get(SOURCE_URL_PARAM);
    const minimumContrastRatioParam = searchParams.get(MINIMUM_CONTRAST_RATIO_PARAM);

    const [minimumContrastRatio, setMinimumContrastRatio] = useState<number>(
        getNumberParam(minimumContrastRatioParam, 3),
    );
    const [document, setDocument] = useState(sourceUrlParam);

    const [sourceRow, setSourceRow] = useState(PreviewRow());
    const [resultRow, setResultRow] = useState(PreviewRow());
    const apiKey = useContext(ApiKeyContext);

    useEffect(() => {
        const searchParamsObj: Record<string, string> = {};

        if (document) {
            searchParamsObj[SOURCE_URL_PARAM] = document;
        }

        if (minimumContrastRatio) {
            searchParamsObj[MINIMUM_CONTRAST_RATIO_PARAM] = `${minimumContrastRatio}`;
        }

        setSearchParams(searchParamsObj, { replace: true });
    }, [document, minimumContrastRatio, setSearchParams]);

    useEffect(() => {
        const sourceCard = CardBuilder.ofType('documentUrl')
            .withTitle('Source Document')
            .withData({ documentUrl: document ?? '' })
            .build();
        setSourceRow(PreviewRow(sourceCard));
    }, [document, setSourceRow]);

    useEffect(() => {
        const resultCard = CardBuilder.ofType('documentUrl')
            .withTitle(`AGS result with improved contrast`)
            .withData({
                documentUrl: getImproveArtworkContrastUrl(document, minimumContrastRatio || 3, apiKey) ?? '',
                backgroundColor: 'FFFFFF',
            })
            .build();
        setResultRow(PreviewRow(resultCard));
    }, [document, minimumContrastRatio, apiKey, setResultRow]);

    const setDocumentUrl = (docUrlOrToken: string) => {
        setDocument(getUrl(docUrlOrToken));
    };

    return (
        <Page>
            <Page.Title>Improve Artwork Contrast</Page.Title>

            <Page.Controls>
                <Control>
                    <Control.Title>Source Document or Template</Control.Title>
                    <CustomSelector
                        selectorLabel="Document (or template)"
                        textfieldLabel="Document URL, Template URL or Template Token"
                        data={predefinedDocuments}
                        value={document ?? ''}
                        setValue={setDocumentUrl}
                        validator={isUrl}
                    />
                </Control>

                <Control>
                    <Control.Title>Minimum Contrast Ratio</Control.Title>
                    <TextField
                        label="Minimum contrast ratio"
                        onChange={(e) => setMinimumContrastRatio(+e.target.value)}
                        type="number"
                        value={minimumContrastRatio}
                        helpText="Value should be in the range of 3 and 4.5 for good results."
                    />
                </Control>
            </Page.Controls>

            <Page.Content>
                <PreviewSection
                    introduction={
                        'This is a page to test the improve overall contrast of document endpoint. Fill in the source document and desired contrast ratio to see the result.'
                    }
                    showIntro={!document}
                    rows={PreviewRows(sourceRow, resultRow)}
                />
            </Page.Content>
        </Page>
    );
};
