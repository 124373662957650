import { lazy } from 'react';

// Hidden page imports
// Exported from a separate file to enable fast refresh during development

export const BatchResizeByProduct = lazy(() => import('../Pages/BatchResizeByProduct'));
export const BatchResizeByDocument = lazy(() => import('../Pages/BatchResizeByDocument'));
export const BatchTransferCustomization = lazy(() => import('../Pages/BatchTransferCustomization'));
export const BatchTransferStyle = lazy(() => import('../Pages/BatchTransferStyle'));
export const SettingsPage = lazy(() => import('../Pages/Settings'));
export const KrReportMainOptionPage = lazy(() => import('../Pages/KrReportMainOptionPage'));
export const KrReportResizePage = lazy(() => import('../Pages/KrReportPages/ResizePage'));
export const KrReportAdjustForSubstratePage = lazy(() => import('../Pages/KrReportPages/AdjustForSubstratePage'));
export const KrReportAdjustForDecorationTechnologyPage = lazy(
    () => import('../Pages/KrReportPages/AdjustForDecorationTechnology'),
);
export const KrReportAdjustForSurfacePage = lazy(() => import('../Pages/KrReportPages/AdjustForSurface'));
export const KrReportImproveContrastPage = lazy(() => import('../Pages/KrReportPages/ImproveContrastPage'));
export const KrReportTransferStylePage = lazy(() => import('../Pages/KrReportPages/TransferStylePage'));
export const KrReportTransferCustomizationPage = lazy(() => import('../Pages/KrReportPages/TransfetCustomization'));
export const GenerateV3WithSherbertResultsPage = lazy(() => import('../Pages/GenerateV3WithSherbertResultsPage'));
export const GenerateWithDynamicLayoutPage = lazy(() => import('../Pages/GenerateWithDynamicLayoutPage'));
export const BatchGenerateFromFullBleed = lazy(() => import('../Pages/BatchGenerateFromFullBleed'));
export const BatchSherbertVsBrandExtraction = lazy(() => import('../Pages/BatchSherbertExtractionVsBrandExtraction'));
export const HiddenPageIndexPage = lazy(() => import('../Pages/HiddenPagesIndexPage'));
export const QaPage = lazy(() => import('../Pages/QA/QaPage'));
export const ResizeQaPage = lazy(() => import('../Pages/QA/ResizeQaPage'));
export const ComposeQa = lazy(() => import('../Pages/QA/ComposeQa'));
export const PersonalizedGallery = lazy(() => import('../Pages/PersonalizedGallery'));
export const PurposelessTransferCustomizations = lazy(() => import('../Pages/PurposelessTransferCustomizations'));
export const PreviewCardVisualizationsPage = lazy(() => import('../Pages/PreviewCardVisualizationsPage'));
export const LayoutComposerVsGenDesignResults = lazy(() => import('../Pages/LayoutComposerVsGenDesignResults'));
