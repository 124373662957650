import { Button, TextField } from '@cimpress/react-components';
import { useContext, useState } from 'react';
import './ApiKeyComponent.css';
import { ApiKeyContext } from '../../App';
import { AGS_API_KEY } from '../../constants';

const ApiKeyComponent = ({ setApiKey }: { setApiKey: (apiKey: string) => void }) => {
    const apiKey = useContext(ApiKeyContext);
    const [apiKeyInput, setApiKeyInput] = useState(apiKey);
    const [showApiTextField, setShowApiTextField] = useState(false);

    function setBtnOnClick() {
        setApiKey(apiKeyInput);
        setShowApiTextField(false);
    }

    function ApiKeyComponent() {
        return (
            <div className="api-key-block">
                <div className="api-key-txt">
                    API Key: <em>{`${apiKey} `}</em>
                </div>
                <Button
                    className="change-btn"
                    size="sm"
                    onClick={() => {
                        setShowApiTextField(true);
                    }}
                >
                    Change
                </Button>
            </div>
        );
    }

    function SetApiKeyButton() {
        return (
            <Button
                className="api-key-block-child"
                onClick={() => {
                    setShowApiTextField(true);
                }}
            >
                Set API Key
            </Button>
        );
    }

    return (
        <div>
            {showApiTextField ? (
                <div className="api-key-block">
                    <TextField
                        id="api-key"
                        value={apiKeyInput}
                        label="Api Key"
                        className="api-key-block-child"
                        onChange={(e) => {
                            setApiKeyInput(e.target.value);
                        }}
                    />
                    <Button className="api-key-block-child" onClick={setBtnOnClick}>
                        Set
                    </Button>
                </div>
            ) : apiKey && apiKey !== AGS_API_KEY ? (
                <ApiKeyComponent />
            ) : (
                <SetApiKeyButton />
            )}
        </div>
    );
};

export default ApiKeyComponent;
