import { TextField } from '@cimpress/react-components';
import { useState } from 'react';
import { getPreviewUrl, isStringEmptyOrWhitespace, useDebouncedEffect, useUpdateEffect } from '../../../tools';
import { type CropFractions, CropFractionsEditor, ImageUrlSelector, LoadingContainer } from '../../common';
import { Control } from '../../layout';
import type { ImageAsset } from './types';

import classes from './ImageAssetEditor.module.css';

export interface ImageAssetEditorProps {
    initialValue?: ImageAsset;
    onChange: (value?: ImageAsset) => void;
}

interface ImageState {
    originalSourceUrl?: string;
    printUrl?: string;
    previewUrl?: string;
    width?: number;
    height?: number;
    cropFractions?: CropFractions;
}

function assetToState(imageAsset?: ImageAsset): ImageState {
    if (!imageAsset) {
        return {};
    }

    return {
        originalSourceUrl: imageAsset.originalSourceUrl,
        printUrl: imageAsset.printUrl,
        previewUrl: imageAsset.previewUrl,
        width: parseSize(imageAsset.width),
        height: parseSize(imageAsset.height),
        cropFractions: imageAsset.cropFractions,
    };
}

function parseSize(size: string): number | undefined {
    const sizeRegex = /^[0-9.]+px$/;

    return sizeRegex.test(size) ? +size.substring(0, size.length - 2) : undefined;
}

interface ImageDimensions {
    width: number;
    height: number;
}

async function getImageDimensions(imageUrl: string, signal: AbortSignal): Promise<ImageDimensions | undefined> {
    return new Promise<ImageDimensions | undefined>((resolve) => {
        const image = new Image();

        const abortRequest = () => {
            image.src = '';
            resolve(undefined);
        };

        if (signal.aborted) {
            abortRequest();
        }

        signal.addEventListener('abort', abortRequest);

        image.onload = () => {
            signal.removeEventListener('abort', abortRequest);
            resolve({ width: image.naturalWidth, height: image.naturalHeight });
        };

        image.onerror = () => {
            signal.removeEventListener('abort', abortRequest);
            resolve(undefined);
        };

        image.src = imageUrl;
    });
}

export const ImageAssetEditor = ({ initialValue, onChange }: ImageAssetEditorProps) => {
    const [image, setImage] = useState(() => assetToState(initialValue));

    const [url, setUrl] = useState(image.originalSourceUrl ?? '');
    const [isImageLoading, setIsImageLoading] = useState(false);

    const [isWidthDirty, setIsWidthDirty] = useState(false);
    const [isHeightDirty, setIsHeightDirty] = useState(false);

    const { originalSourceUrl, printUrl, previewUrl, width, height, cropFractions } = image;

    useUpdateEffect(() => {
        if (
            isStringEmptyOrWhitespace(originalSourceUrl) ||
            isStringEmptyOrWhitespace(printUrl) ||
            isStringEmptyOrWhitespace(previewUrl) ||
            !width ||
            !height
        ) {
            onChange(undefined);
            return;
        }

        const newImage: ImageAsset = {
            originalSourceUrl: originalSourceUrl!,
            printUrl: printUrl!,
            previewUrl: previewUrl!,
            width: `${width}px`,
            height: `${height}px`,
        };

        if (cropFractions) {
            newImage.cropFractions = cropFractions;
        }

        onChange(newImage);
    }, [originalSourceUrl, printUrl, previewUrl, width, height, cropFractions, onChange]);

    useDebouncedEffect(
        () => {
            if (!url) {
                setIsImageLoading(false);
                setImage({});
                return;
            }

            const abortController = new AbortController();

            setIsImageLoading(true);

            Promise.all([
                getPreviewUrl(url, abortController.signal),
                getImageDimensions(url, abortController.signal),
            ]).then(([previewUrl, dimensions]) => {
                if (!abortController.signal.aborted) {
                    setImage({
                        originalSourceUrl: url,
                        printUrl: url,
                        previewUrl,
                        width: dimensions?.width,
                        height: dimensions?.height,
                    });
                    setIsWidthDirty(true);
                    setIsHeightDirty(true);
                    setIsImageLoading(false);
                }
            });

            return () => {
                abortController.abort();
            };
        },
        [url],
        300,
    );

    return (
        <LoadingContainer isLoading={isImageLoading}>
            <Control spacing="compact">
                <ImageUrlSelector
                    url={url}
                    onChange={(value) => setUrl(value ?? '')}
                    // We're doing further processing after the URL is selected, so don't hide the loading overlay just yet
                    setIsImageLoading={(value) => (value ? setIsImageLoading(true) : undefined)}
                />

                <Control spacing="compact" className={classes.columns}>
                    <TextField
                        label="Width (px)"
                        type="number"
                        required
                        value={width}
                        status={isWidthDirty && !width ? 'error' : undefined}
                        onChange={(e) => setImage((prev) => ({ ...prev, width: +e.target.value }))}
                        onInput={() => setIsWidthDirty(true)}
                        onBlur={() => setIsWidthDirty(true)}
                    />

                    <TextField
                        label="Height (px)"
                        type="number"
                        required
                        value={height}
                        status={isHeightDirty && !height ? 'error' : undefined}
                        onChange={(e) => setImage((prev) => ({ ...prev, height: +e.target.value }))}
                        onInput={() => setIsHeightDirty(true)}
                        onBlur={() => setIsHeightDirty(true)}
                    />
                </Control>

                <CropFractionsEditor
                    cropFractions={cropFractions}
                    onChange={(value) => setImage((prev) => ({ ...prev, cropFractions: value }))}
                />
            </Control>
        </LoadingContainer>
    );
};
