import { Button, TextField } from '@cimpress/react-components';
import IconRemove from '@cimpress-technology/react-streamline-icons/lib/IconRemove';
import { useState } from 'react';
import { isStringEmptyOrWhitespace, useUpdateEffect } from '../../../tools';
import { Control } from '../../layout';
import type { TextAsset } from './types';

export interface TextAssetEditorProps {
    assetId: string;
    initialValue?: TextAsset;
    onChange: (assetId: string, value: TextAsset) => void;
    onRemove: (assetId: string) => void;
}

interface TextState {
    content: string;
    purpose: string;
}

function assetToState(textAsset?: TextAsset): TextState {
    if (!textAsset) {
        return {
            content: '',
            purpose: '',
        };
    }

    const { content, purpose } = textAsset;

    return {
        content: content ?? '',
        purpose: purpose ?? '',
    };
}

export const TextAssetEditor = ({ assetId, initialValue, onChange, onRemove }: TextAssetEditorProps) => {
    const [text, setText] = useState(() => assetToState(initialValue));
    const [isContentDirty, setIsContentDirty] = useState(false);

    const { content, purpose } = text;

    useUpdateEffect(() => {
        if (!isStringEmptyOrWhitespace(content)) {
            onChange(assetId, {
                content,
                purpose,
            });
        }
    }, [assetId, content, purpose, onChange]);

    return (
        <Control spacing="compact">
            <TextField
                label="Content"
                required
                value={content}
                status={isContentDirty && isStringEmptyOrWhitespace(content) ? 'error' : undefined}
                onChange={(e) => setText((prev) => ({ ...prev, content: e.target.value }))}
                onInput={() => setIsContentDirty(true)}
                onBlur={() => setIsContentDirty(true)}
                rightAddon={
                    <Button
                        color="danger"
                        variant="primary"
                        icon={<IconRemove className="icon-addon" />}
                        aria-label="Remove text asset"
                        onClick={() => onRemove(assetId)}
                    />
                }
            />

            <TextField
                label="Purpose"
                required={false}
                value={purpose}
                onChange={(e) => setText((prev) => ({ ...prev, purpose: e.target.value }))}
            />
        </Control>
    );
};
