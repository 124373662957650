import { Button, Select, Spinner, TextField } from '@cimpress/react-components';
import { useContext, useState } from 'react';
import {
    CardBuilder,
    Content,
    Control,
    Page,
    PreviewRow,
    PreviewRows,
    PreviewSection,
    type SelectorOption,
} from '../Components';
import { useCancelableEffect } from '../tools';
import { ApiKeyContext } from '../App';
import { getImageFromTextUrl } from '../api';
import { useGenerateImageFromTextParams } from '../Components/GenerateImageFromText/useGenerateImageFromText';

const modelOptions = [
    {
        label: 'Basic',
        value: 'basic',
    },
    {
        label: 'Standard',
        value: 'standard',
    },
    {
        label: 'Premium',
        value: 'premium',
    },
];

const mediaTypeOptions = [
    { label: 'Raster', value: 'raster' },
    { label: 'Vector', value: 'vector' },
];

const aspectRatioOptions = [
    { label: 'Square', value: 'square' },
    { label: 'Vertical', value: 'vertical' },
    { label: 'Horizontal', value: 'horizontal' },
];

export const GenerateImageFromText = () => {
    const {
        prompt,
        modelSelectedOption,
        aspectRatioSelectedOption,
        mediaTypeSelectedOption,
        setPrompt,
        setModelSelectedOption,
        setAspectRatioSelectedOption,
        setMediaTypeSelectedOption,
    } = useGenerateImageFromTextParams();

    const [resultRow, setResultRow] = useState(PreviewRow());
    const [isLoading, setIsLoading] = useState(false);
    const [callGenerate, setCallGenerate] = useState(false);
    const apiKey = useContext(ApiKeyContext);

    useCancelableEffect(
        (signal) => {
            (async () => {
                if (callGenerate && prompt) {
                    setIsLoading(true);

                    try {
                        const resultImageUrl: string = await getImageFromTextUrl({
                            prompt: prompt,
                            modelSelectedOption: modelSelectedOption.value,
                            mediaTypeSelectedOption: mediaTypeSelectedOption.value,
                            aspectRatioSelectedOption: aspectRatioSelectedOption.value,
                            signal,
                            apiKey,
                        });

                        const imageResultCard = CardBuilder.ofType('imageUrl')
                            .withTitle(`Image`)
                            .withData({
                                imageUrl: resultImageUrl,
                            })
                            .build();
                        setResultRow(PreviewRow(imageResultCard));
                    } catch (error) {
                        if (signal.aborted) {
                            return;
                        }

                        const resultCard = CardBuilder.ofType('documentUrl')
                            .withTitle('Result Document')
                            .withLoader(async () => {
                                throw error;
                            })
                            .build();
                        setResultRow(PreviewRow(resultCard));
                    }

                    setIsLoading(false);
                    setCallGenerate(false);
                }
            })();
        },
        [
            callGenerate,
            prompt,
            apiKey,
            modelSelectedOption.value,
            aspectRatioSelectedOption.value,
            mediaTypeSelectedOption.value,
        ],
    );

    const onModelChange = (selected: SelectorOption | null | undefined) => {
        if (!selected) {
            return;
        }
        setModelSelectedOption(selected);
    };

    const onMediaTypeChange = (selected: SelectorOption | null | undefined) => {
        if (!selected) {
            return;
        }
        setMediaTypeSelectedOption(selected);
    };

    const onAspectRatioChange = (selected: SelectorOption | null | undefined) => {
        if (!selected) {
            return;
        }
        setAspectRatioSelectedOption(selected);
    };

    function onClickGenerate() {
        setCallGenerate(true);
    }

    return (
        <Page>
            <Page.Title>Generate Images from Text (Beta)</Page.Title>

            <Page.Controls>
                <Control>
                    <Control.Title>Prompt</Control.Title>
                    <TextField label="Prompt" onChange={(e) => setPrompt(e.target.value)} value={prompt ?? ''} />
                </Control>

                <Control>
                    <Control.Title>Model</Control.Title>
                    <Select onChange={onModelChange} value={modelSelectedOption} options={modelOptions} label="Model" />
                </Control>
                <Control>
                    <Control.Title>Media Type</Control.Title>
                    <Select
                        onChange={onMediaTypeChange}
                        value={mediaTypeSelectedOption}
                        options={mediaTypeOptions}
                        label="MediaType"
                    />
                </Control>
                <Control>
                    <Control.Title>AspectRatio</Control.Title>
                    <Select
                        onChange={onAspectRatioChange}
                        value={aspectRatioSelectedOption}
                        options={aspectRatioOptions}
                        label="Aspect Ratio"
                    />
                </Control>

                <Control>
                    <Button onClick={onClickGenerate}>Generate</Button>
                </Control>
            </Page.Controls>

            <Page.Content>
                {isLoading ? (
                    <Content.Card sticky size="large">
                        <Content.Section>
                            <Spinner />
                        </Content.Section>
                    </Content.Card>
                ) : (
                    <PreviewSection
                        introduction={
                            'This is a page to test the Generate Image From Text endpoint. Fill in the prompt to see generated results.'
                        }
                        showIntro={!prompt}
                        rows={PreviewRows(resultRow)}
                    />
                )}
            </Page.Content>
        </Page>
    );
};

export default GenerateImageFromText;
