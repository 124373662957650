import { TextField } from '@cimpress/react-components';
import { useState } from 'react';
import {
    getImageDimensions,
    getPreviewUrl,
    isStringEmptyOrWhitespace,
    parseSize,
    useDebouncedEffect,
    useUpdateEffect,
} from '../../../tools';
import { ImageUrlSelector, LoadingContainer, type SelectorOption } from '../../common';
import { Control } from '../../layout';
import type { InspirationImageAsset, InspirationImageSelectorOption } from './types';

import classes from './InspirationImageAssetEditor.module.css';

export interface InspirationImageAssetEditorProps {
    initialValue?: InspirationImageAsset | null;
    onChange: (value: InspirationImageAsset | null) => void;
    sampleValues?: InspirationImageSelectorOption[];
}

interface InspirationImageState {
    originalSourceUrl?: string;
    printUrl?: string;
    previewUrl?: string;
    width?: number;
    height?: number;
}

function assetToState(imageAsset: InspirationImageAsset): InspirationImageState {
    return {
        ...imageAsset,
        width: parseSize(imageAsset.width),
        height: parseSize(imageAsset.height),
    };
}

export const InspirationImageAssetEditor = ({
    initialValue,
    onChange,
    sampleValues,
}: InspirationImageAssetEditorProps) => {
    const [image, setImage] = useState(() => (initialValue ? assetToState(initialValue) : null));

    const [url, setUrl] = useState(image?.originalSourceUrl ?? '');
    const [isImageLoading, setIsImageLoading] = useState(false);

    const [isWidthDirty, setIsWidthDirty] = useState(false);
    const [isHeightDirty, setIsHeightDirty] = useState(false);

    const { originalSourceUrl, printUrl, previewUrl, width, height } = image ?? {};

    const sampleUrlSelectorOptions: SelectorOption[] =
        sampleValues && sampleValues.length
            ? sampleValues.map((sample) => {
                  return { label: sample.label, value: sample.value.previewUrl };
              })
            : [];

    useUpdateEffect(() => {
        if (
            isStringEmptyOrWhitespace(originalSourceUrl) ||
            isStringEmptyOrWhitespace(printUrl) ||
            isStringEmptyOrWhitespace(previewUrl) ||
            !width ||
            !height
        ) {
            onChange(null);
            return;
        }

        const newImage: InspirationImageAsset = {
            originalSourceUrl: originalSourceUrl!,
            printUrl: printUrl!,
            previewUrl: previewUrl!,
            width: `${width}px`,
            height: `${height}px`,
        };

        onChange(newImage);
    }, [originalSourceUrl, printUrl, previewUrl, width, height, onChange]);

    useDebouncedEffect(
        () => {
            if (!url) {
                setIsImageLoading(false);
                setImage(null);
                return;
            }

            const abortController = new AbortController();

            setIsImageLoading(true);

            Promise.all([
                getPreviewUrl(url, abortController.signal),
                getImageDimensions(url, abortController.signal),
            ]).then(([previewUrl, dimensions]) => {
                if (!abortController.signal.aborted) {
                    setImage({
                        originalSourceUrl: url,
                        printUrl: url,
                        previewUrl,
                        width: dimensions?.width,
                        height: dimensions?.height,
                    });
                    setIsWidthDirty(true);
                    setIsHeightDirty(true);
                    setIsImageLoading(false);
                }
            });

            return () => {
                abortController.abort();
            };
        },
        [url],
        300,
    );

    return (
        <LoadingContainer isLoading={isImageLoading}>
            <Control spacing="compact">
                <ImageUrlSelector
                    url={url}
                    onChange={(value) => setUrl(value ?? '')}
                    // We're doing further processing after the URL is selected, so don't hide the loading overlay just yet
                    setIsImageLoading={(value) => (value ? setIsImageLoading(true) : undefined)}
                    selectorOptions={sampleUrlSelectorOptions}
                />

                <Control spacing="compact" className={classes.columns}>
                    <TextField
                        label="Width (px)"
                        type="number"
                        required
                        value={width}
                        status={isWidthDirty && !width ? 'error' : undefined}
                        onChange={(e) => setImage((prev) => ({ ...prev, width: +e.target.value }))}
                        onInput={() => setIsWidthDirty(true)}
                        onBlur={() => setIsWidthDirty(true)}
                    />

                    <TextField
                        label="Height (px)"
                        type="number"
                        required
                        value={height}
                        status={isHeightDirty && !height ? 'error' : undefined}
                        onChange={(e) => setImage((prev) => ({ ...prev, height: +e.target.value }))}
                        onInput={() => setIsHeightDirty(true)}
                        onBlur={() => setIsHeightDirty(true)}
                    />
                </Control>
            </Control>
        </LoadingContainer>
    );
};
