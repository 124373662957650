import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import type { SelectorOption } from '../common';
import { DEFAULT_CULTURE_OPTION, DEFAULT_PANEL_SELECTOR_OPTION } from '../../constants';

export enum Culture {
    EnUs = 'en-us',
    FrFr = 'fr-fr',
    DeDe = 'de-de',
    NlNl = 'nl-nl',
    EsEs = 'es-es',
    ItIt = 'it-it',
    EnCa = 'en-ca',
    EnIn = 'en-in',
    EnSg = 'en-sg',
    EnAu = 'en-au',
    EnNz = 'en-nz',
    EnGb = 'en-gb',
    EnIe = 'en-ie',
}

interface UseGenerateParams {
    assets: any;
    sourceDocumentUrl: string;
    surfaceSpecificationUrl: string;
    surfaceSpecification: any;
    productName: string;
    panels: SelectorOption;
    dpiThreshold: number | undefined;
    culture: SelectorOption;
    testingClient: string;
    layout: any;
    targetLargeFontSizeInPt: string;
    setAssets: (assets: any) => void;
    setSourceDocumentUrl: (url: string) => void;
    setSurfaceSpecificationUrl: (url: string) => void;
    setSurfaceSpecification: (surfaceSpec: any) => void;
    setProductName: (productName: string) => void;
    setPanels: (panels: SelectorOption) => void;
    setDpiThreshold: (dpiThreshold: number | undefined) => void;
    setTestingClient: (testingClient: string) => void;
    setLayout: (layout: any) => void;
    setTargetLargeFontSizeInPt: (targetLargeFontSizeInPt: string) => void;
    setCulture: (culture: SelectorOption) => void;
}

export const useGenerateParams = (): UseGenerateParams => {
    const [searchParams, setSearchParams] = useSearchParams();

    const assetsParam = searchParams.get('assets');
    const surfaceSpecificationParam = searchParams.get('surfaceSpecification');
    const surfaceSpecificationUrlParam = searchParams.get('surfaceSpecificationUrl');
    const sourceDocumentUrlParam = searchParams.get('sourceDocumentUrl');
    const productNameParam = searchParams.get('productName');
    const testingClientParam = searchParams.get('testingClient');
    const layoutParam = searchParams.get('layout');
    const targetLargeFontSizeInPtParam = searchParams.get('targetLargeFontSizeInPt');
    const panelsQueryParam = searchParams.get('panels');
    const dpiThresholdParam = searchParams.get('dpiThreshold');
    const cultureParam = searchParams.get('culture');

    const [targetLargeFontSizeInPt, setTargetLargeFontSizeInPt] = useState(targetLargeFontSizeInPtParam || '');
    const [layout, setLayout] = useState(layoutParam ? JSON.parse(layoutParam) : undefined);
    const [assets, setAssets] = useState(assetsParam ? JSON.parse(assetsParam) : undefined);
    const [surfaceSpecificationUrl, setSurfaceSpecificationUrl] = useState(surfaceSpecificationUrlParam || '');
    const [surfaceSpecification, setSurfaceSpecification] = useState(
        surfaceSpecificationParam ? JSON.parse(surfaceSpecificationParam) : undefined,
    );
    const [sourceDocumentUrl, setSourceDocumentUrl] = useState(sourceDocumentUrlParam || '');
    const [testingClient, setTestingClient] = useState(testingClientParam || '');
    const [productName, setProductName] = useState(productNameParam || '');
    const [panels, setPanels] = useState<SelectorOption>(
        panelsQueryParam ? { label: panelsQueryParam, value: panelsQueryParam } : DEFAULT_PANEL_SELECTOR_OPTION,
    );

    const [dpiThreshold, setDpiThreshold] = useState(
        dpiThresholdParam ? Number.parseFloat(dpiThresholdParam) : undefined,
    );
    const [culture, setCulture] = useState<SelectorOption>(
        cultureParam ? { label: cultureParam, value: cultureParam } : DEFAULT_CULTURE_OPTION,
    );

    useEffect(() => {
        const searchParamsObj: Record<string, string> = {};

        if (surfaceSpecificationUrl) {
            searchParamsObj['surfaceSpecificationUrl'] = surfaceSpecificationUrl;
        }

        if (surfaceSpecification) {
            searchParamsObj['surfaceSpecification'] = JSON.stringify(surfaceSpecification);
        }

        if (sourceDocumentUrl) {
            searchParamsObj['sourceDocumentUrl'] = sourceDocumentUrl;
        }

        if (assets) {
            searchParamsObj['assets'] = JSON.stringify(assets);
        }

        if (productName) {
            searchParamsObj['productName'] = productName;
        }

        if (panels) {
            searchParamsObj['panels'] = panels.value;
        }

        if (dpiThreshold) {
            searchParamsObj['dpiThreshold'] = dpiThreshold.toString();
        }

        if (testingClient) {
            searchParamsObj['testingClient'] = testingClient;
        }

        if (layout) {
            searchParamsObj['layout'] = JSON.stringify(layout);
        }

        if (targetLargeFontSizeInPt) {
            searchParamsObj['targetLargeFontSizeInPt'] = targetLargeFontSizeInPt;
        }

        if (culture) {
            searchParamsObj['culture'] = culture.value;
        }

        setSearchParams(searchParamsObj);
    }, [
        assets,
        surfaceSpecificationUrl,
        surfaceSpecification,
        sourceDocumentUrl,
        setSearchParams,
        productName,
        dpiThreshold,
        culture,
        panels,
        testingClient,
        layout,
        targetLargeFontSizeInPt,
    ]);

    return {
        assets,
        surfaceSpecificationUrl,
        surfaceSpecification,
        sourceDocumentUrl,
        productName,
        panels,
        dpiThreshold,
        culture,
        testingClient,
        layout,
        targetLargeFontSizeInPt,
        setAssets,
        setSurfaceSpecificationUrl,
        setSurfaceSpecification,
        setSourceDocumentUrl,
        setProductName,
        setPanels,
        setDpiThreshold,
        setCulture,
        setTestingClient,
        setLayout,
        setTargetLargeFontSizeInPt,
    };
};
