import { useContext, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ApiKeyContext } from '../../App';
import { API_KEY_PARAM_NAME } from '../../constants';

export const ApiKeyWrapper = (props: { children: any }) => {
    const { children } = props;
    const apiKey = useContext(ApiKeyContext);

    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const apiKeySearchParam = searchParams.get(API_KEY_PARAM_NAME);
        if (apiKey && !apiKeySearchParam) {
            searchParams.set(API_KEY_PARAM_NAME, apiKey);
        }
        setSearchParams(searchParams);
    }, [apiKey, searchParams, setSearchParams]);
    return children;
};
