import { Button } from '@cimpress/react-components';
import { useCallback, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useUpdateEffect } from '../../../tools';
import { Control } from '../../layout';
import { TextAssetEditor } from './TextAssetEditor';
import type { TextAsset } from './types';

export interface TextListEditorProps {
    initialValue?: TextAsset[];
    onChange: (value?: TextAsset[]) => void;
}

interface TextListItem {
    id: string;
    asset?: TextAsset;
}

export const TextListEditor = ({ initialValue, onChange }: TextListEditorProps) => {
    const [textList, setTextList] = useState<TextListItem[]>(() =>
        (initialValue ?? []).map((asset) => ({ id: uuidv4(), asset })),
    );

    const addTextAsset = () => {
        setTextList((prev) => [...prev, { id: uuidv4() }]);
    };

    const removeTextAsset = useCallback((id: string) => {
        setTextList((prev) => prev.filter((item) => item.id !== id));
    }, []);

    const updateTextAsset = useCallback((id: string, asset: TextAsset) => {
        setTextList((prev) => {
            const existingAsset = prev.find((a) => a.id === id)!;

            // This is technically "illegal" in React as we should avoid mutating the state,
            // but this whole component doesn't really care about the wrapper object reference.
            // The state update will be picked up by React anyway because we're returning a new array below.
            existingAsset.asset = asset;

            return [...prev];
        });
    }, []);

    useUpdateEffect(() => {
        if (textList.every(({ asset }) => asset?.content)) {
            onChange(textList.map((item) => item.asset!));
        } else {
            onChange(undefined);
        }
    }, [textList, onChange]);

    return (
        <Control>
            {textList.map(({ id, asset }) => (
                <TextAssetEditor
                    key={id}
                    assetId={id}
                    initialValue={asset}
                    onChange={updateTextAsset}
                    onRemove={removeTextAsset}
                />
            ))}

            <Button variant="primary" blockLevel onClick={addTextAsset}>
                Add new text
            </Button>
        </Control>
    );
};
