import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SOURCE_URL_PARAM, TARGET_URL_PARAM } from '../../constants';

interface TransferStyleParams {
    sourceUrl: string;
    targetUrl: string;
    setSourceUrl: (sourceUrl: string) => void;
    setTargetUrl: (targetUrl: string) => void;
}

export const useTransferStyleParams = (): TransferStyleParams => {
    const [searchParams, setSearchParams] = useSearchParams();
    const sourceUrlParam = searchParams.get(SOURCE_URL_PARAM);
    const targetUrlParam = searchParams.get(TARGET_URL_PARAM);

    const [sourceUrl, setSourceUrl] = useState(sourceUrlParam ?? '');
    const [targetUrl, setTargetUrl] = useState(targetUrlParam ?? '');

    useEffect(() => {
        const searchParamsObj: Record<string, string> = {};
        if (sourceUrl) {
            searchParamsObj[SOURCE_URL_PARAM] = sourceUrl;
        }

        if (targetUrl) {
            searchParamsObj[TARGET_URL_PARAM] = targetUrl;
        }
        setSearchParams(searchParamsObj, { replace: true });
    }, [sourceUrl, targetUrl, setSearchParams]);
    return {
        sourceUrl,
        targetUrl,
        setSourceUrl,
        setTargetUrl,
    };
};
